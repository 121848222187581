<template>
  <div>
    <h1>Boost UpSell</h1>
    <v-row>
      <v-col cols="12" v-for="(item, index) in upsells" :key="index">
        <v-card class="pa-6 d-flex align-center" style="gap: 16px;" @click="goToLink(item.url)">
          <div class="border pa-3 rounded">
            <div class="info rounded d-flex justify-center align-center" style="width: 38px; height: 38px;">
              <i :class="item.icon" style="font-size: 24px;"></i>
            </div>
          </div>
          <div class="flex-1">
            <h3>{{ item.title }}</h3>
            <p class="mb-0">{{ item.description }}</p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      upsells: [
        {
          url: {
            name: 'website.upsell.products_from_the_same_collections',
            params: {
              widgetType: 'moreCollection',
            },
          },
          icon: 'ri-t-shirt-2-line white--text',
          color: 'success',
          title: 'Products from the same collections',
          description: 'Here you can customize the interface of your recommendation widgets.',
        },
        {
          url: {
            name: 'website.upsell.post_purchase',
          },
          icon: 'ri-shopping-bag-4-line white--text',
          color: 'primary',
          title: 'Post purchase',
          description:
            'Upsell offer has been proven that it may boost conversion rate to 16%. See how it looks or watch video to know how it works.',
        },
        {
          url: {
            name: 'website.upsell.quantity.list',
          },
          icon: 'ri-discount-percent-line white--text',
          color: 'error',
          title: 'Quantity Discounts',
          description: 'Offer customers to buy in a bulk of products with your attractive discount.',
        },
      ],
    };
  },
  methods: {
    goToLink(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  transition: background-color 0.5s;
  cursor: pointer;
  height: 100%;
  &:hover {
    background-color: #f5f5f5 !important;
  }
  p {
    color: gray;
    font-size: 14px;
  }
}
</style>
